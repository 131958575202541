// /* You can add global styles to this file, and also import other style files */
// @import '~@angular/material/prebuilt-themes/indigo-pink.css';

// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use "@angular/material/theming";
// // Plus imports for other components in your app.
// @import "~bootstrap/scss/bootstrap-grid.scss";

// @include mat-core();

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "bootstrap/scss/bootstrap-grid.scss";
// Plus imports for other components in your app.
@import 'material-icons/iconfont/material-icons.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: "Lato";
  src: url(assets/fonts/Lato-Regular.ttf);
}

$lato-theme: mat.define-theme(
 (
  typography: (
    brand-family: "Lato",
    plain-family: "Lato",
  ),
 )
 );

body, h1, h2, h3, h4, h5, h6, .mat-list-item {
  font-family: 'Lato' !important;
  color: #000;
  @include mat.all-component-themes($lato-theme);

}

html, body { height: 100%; }

body {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.mat-mdc-text-field-wrapper{
  background-color: transparent !important;
  border-radius: 8px;
  height: 60px !important;
}

.mat-mdc-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: #282828 !important;
  }
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  background-color: transparent;
}

.mat-badge{
  cursor: pointer;
}

.mat-form-field-required-marker {
  color: #c50866 !important;
}

.placeholder {
  background-color: transparent;
}

.mat-mdc-dialog-content {
  overflow-x: hidden !important;
}

.mat-mdc-dialog-surface{
  border-radius: 10px !important;
}

// $scrollbar-color: #96a5b8;
$scrollbar-color: #005C9C;
$scrollbar-color-hover: #282828;
$setting-pages-bg-color: #F0F0F0;

/*** STYLE FOR TINY SCROLLBAR ***/
.tiny-scrollbar,
.mat-autocomplete-panel,
.mat-mdc-select-panel,
.mat-expansion-panel-body {
  scrollbar-width: thin; // Firefox
  scrollbar-color: $scrollbar-color transparent;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    &:hover {
      background: $scrollbar-color-hover;
    }
    &:focus {
      background: $scrollbar-color-hover;
    }
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-color-hover;
  }
}
/*** STYLE FOR DEFAULT SCROLLBAR ***/
.default-scrollbar {
  scrollbar-width: thin; // Firefox
  scrollbar-color: $scrollbar-color transparent;
  &::-webkit-scrollbar { // Chrome, Edge, Safari and Opera
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    border-radius: 4px;
    &:hover {
      background: $scrollbar-color-hover;
    }
    &:focus {
      background: $scrollbar-color-hover;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  /*** TOAST MESSAGE: Mat Snackbar CSS START ***/
  .toast-success {
    background-color: #007CB8;
    color: white;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .toast-error {
    background-color: #ff4000; // rgba(255, 0, 0, 0.493);
    color: #ffffff; // #2B2B2B;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .toast-info {
    background-color: #FFFFFF;
    color: #007CB8;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }

  .toast-grey {
    padding: 20px 16px;
    border-radius: 10px;
    background-color: #4c4c4c;
    -webkit-box-shadow: unset;
    -moz-box-shadow: unset;
    box-shadow: unset;
  }
  /*** TOAST MESSAGE: Mat Snackbar CSS END ***/
  /* add account dialog */
  .mdc-dialog__container {
    overflow: hidden;
    border-radius: 10px !important;
  }

  .wizard .mdc-dialog__container {
    border-radius: 0 !important;
  }

  .modal-backdrop {
    z-index: 998;
  }

  main {
    height: 100vh; /* Get the viewport height */
    overflow-y: hidden;
    background-color: var(--bg-main);

    app-mail {
      height: 91.07vh; //100%;
    }
  }

  .loader {
    position: fixed;
    z-index: 99999;
    width: 10%;
    height: 15%;
    left: 45%;
    top: 42%;
  }
}

/* vFlow - mat-menu-panel conflict */
@mixin vFlowEnabledGroupsCustomSelect() {
  $tooltipColor: #007789;
  $hover-color: #00B9B3;
  .mat-menu-panel {
      min-width: 14.8vw; //12.6vw;
      //max-width: 12.6vw;
      background-color: $tooltipColor;
      border-radius: 0;
      min-height: auto;
  }
  .mat-mdc-menu-item {
      color: white;
      height: 30px;
      align-items: center;
      display: flex;
      &:focus { outline: none; }
  }
  .mat-menu-content:not(:empty) {
      padding-top: 1px;
      padding-bottom: 1px;
  }

  .mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-mdc-menu-item-highlighted:not([disabled]) {
      background-color: $hover-color;
  }
}
.vFlowCustomize+* {
  .mat-menu-panel {
      min-width: 12.6vw;
      max-width: 12.6vw;
      background-color: #007789;
      border-radius: 0;
  }
  .mat-mdc-menu-item {
      color: white;
      height: 30px;
  }
  .mat-menu-content:not(:empty) {
      padding-top: 1px;
      padding-bottom: 1px;
  }

  .mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-mdc-menu-item-highlighted:not([disabled]) {
      background-color: #00B9B3;
  }

  button {
      &.mat-mdc-menu-item {
          align-items: center;
          display: flex;
      }
      &:focus { outline: none; }
  }
}
.vFlowCustomizeEnabledGroups+* {
  @include vFlowEnabledGroupsCustomSelect();
}

.sidebar-signature-100 {
  width: 100% !important;
}

.valign-icon-text {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

.admins-container {
  padding: 18.8px 20px 17px 11px;
  color: var(--MenuTextColor) !important;

  span {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    display: block;
  }

  .admin-count {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

  }
}
